<template>
  <section class="app-main">
    <transition>
      <!-- <keep-alive include=""> -->
      <router-view />
      <!-- </keep-alive> -->
    </transition>
  </section>
</template>

<script>
export default {
  name: 'AppMain',
  computed: {
    key() {
      return this.$route.path
    }
  }
}
</script>

<style scoped>
.app-main {
  /*66 = navbar  */
  height: 100%;
  width: 100%;
  overflow: auto;
  overflow-x: hidden;
}
</style>
