var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"navbar-container"},[_c('div',{staticClass:"navbar-logo"},[_c('LogoView')],1),_c('div',{staticClass:"navbar-menu"},[_c('el-tooltip',{attrs:{"effect":"dark","content":_vm.isScreenfull ? '退出全屏' : '全屏',"placement":"bottom"}},[_c('div',{staticClass:"navbar-menu-item",on:{"click":_vm.screenfull}},[_c('div',{staticClass:"navbar-menu-icon"},[_c('i',{staticClass:"el-icon-full-screen"})])])]),_c('el-dropdown',{staticClass:"avatar-container",on:{"command":_vm.handleCommand}},[_c('div',{staticClass:"avatar-wrapper"},[(_vm.avatar)?_c('el-avatar',{attrs:{"size":35,"src":_vm.avatar}}):_c('div',{staticClass:"avatar-text"},[_vm._v(" "+_vm._s(_vm.nickname.split('')[0])+" ")]),_c('div',[_vm._v(_vm._s(_vm.nickname))])],1),_c('el-dropdown-menu',{staticClass:"user-dropdown",attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('el-dropdown-item',{attrs:{"command":"openUserinfo"}},[_c('span',[_c('i',{staticClass:"el-icon-user"}),_vm._v(" 个人信息 ")])]),_c('el-dropdown-item',{attrs:{"command":"updatePwd"}},[_c('span',[_c('i',{staticClass:"el-icon-unlock"}),_vm._v(" 修改密码 ")])])],1)],1),(_vm.wsStatus == 1 || _vm.wsStatus == 2 || _vm.wsStatus == 3)?_c('div',{staticClass:"ws-status",class:_vm.wsStatus == 1
                    ? 'ws-status-1'
                    : _vm.wsStatus == 2
                    ? 'ws-status-2'
                    : _vm.wsStatus == 3
                    ? 'ws-status-3'
                    : ''}):_vm._e(),_c('el-tooltip',{attrs:{"effect":"dark","content":"退出登录","placement":"bottom"}},[_c('div',{staticClass:"navbar-menu-item",on:{"click":_vm.logout}},[_c('div',{staticClass:"navbar-menu-icon"},[_c('i',{staticClass:"el-icon-switch-button",staticStyle:{"color":"red"}})])])])],1),_c('updatePwd',{ref:"updatePwd"}),_c('userInfo',{ref:"userInfo"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }