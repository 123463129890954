<template>
    <el-dialog
        :title="title"
        :visible.sync="dialogFormVisible"
        width="400px"
        :fullscreen="false"
        :close-on-click-modal="false"
        :modal-append-to-body="false"
        @close="close"
    >
        <el-form ref="form" :model="form" :rules="rules" label-width="100px">
            <el-form-item prop="password" label="旧密码">
                <el-input v-model="form.password" autocomplete="off" />
            </el-form-item>
            <el-form-item prop="newPassword" label="新密码">
                <el-input v-model="form.newPassword" autocomplete="off" />
            </el-form-item>
            <el-form-item prop="confirmPassword" label="确认密码">
                <el-input v-model="form.confirmPassword" autocomplete="off" />
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button @click="close">取 消</el-button>
            <el-button type="primary" @click="save">确 定</el-button>
        </div>
    </el-dialog>
</template>

<script>
export default {
    name: 'UpdatePwdView',
    components: {},
    data() {
        return {
            options: [],
            form: {},
            rules: {
                password: [
                    {
                        required: true,
                        message: '请输入密码',
                        type: 'string',
                        trigger: 'blur'
                    }
                ],
                newPassword: [
                    {
                        required: true,
                        message: '请输入密码',
                        type: 'string',
                        trigger: 'blur'
                    }
                ],
                confirmPassword: [
                    {
                        required: true,
                        message: '请输入重复密码',
                        type: 'string',
                        trigger: 'blur'
                    },
                    {
                        type: 'string',
                        trigger: 'change',
                        validator: async (rule, value, callback) => {
                            const password = this.form.newPassword
                            if (value && password !== value) {
                                return callback(new Error('两次密码输入不一致'))
                            }
                        }
                    }
                ]
            },
            title: '',
            dialogFormVisible: false
        }
    },
    created() {},
    methods: {
        show() {
            this.title = '修改密码'
            this.dialogFormVisible = true
            this.form = {}
        },
        close() {
            this.$refs['form'].resetFields()
            this.form = this.$options.data().form
            this.dialogFormVisible = false
        },
        save() {
            this.$refs['form'].validate(async (valid) => {
                if (valid) {
                    const data = await this.$http.put(
                        '/web/sysUser/updatePassword',
                        this.form
                    )
                    if (data.success) {
                        this.$baseMessage(data.message, 'success')
                        this.close()
                    }
                } else {
                    return false
                }
            })
        }
    }
}
</script>
