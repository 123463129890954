<template>
  <div class="app-page">
    <div class="app-header">
      <NavBar />
    </div>
    <div class="app-content">
      <div class="app-sidebar">
        <SideBar />
      </div>
      <div class="app-main">
        <AppMain />
      </div>
    </div>
  </div>
</template>
<script>
import AppMain from './AppMain'
import NavBar from './NavBar/NavBar'
import SideBar from './SideBar/SideBar.vue'
export default {
  name: 'LayoutView',
  components: { AppMain, NavBar, SideBar },
  data() {
    return {}
  }
}
</script>
<style lang="scss" scoped>
.app-page {
  height: 100%;
  width: 100%;
}

.app-header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 9;
  width: 100%;
  height: 66px;
  transition: width 0.28s;
  background-color: #ffffff;
  // border-bottom: 1px solid #bdbdbd;
  box-shadow: 0 1px 10px -5px #bdbdbd;
}

.app-content {
  position: fixed;
  top: 66px;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: row;
  flex: 1;
  flex-basis: auto;
  box-sizing: border-box;
  min-width: 0;

  .app-sidebar {
    width: 250px;
    background-color: #ffffff;
    border-right: 1px solid #e0e0e0;
    overflow: auto;
    box-sizing: border-box;
    flex-shrink: 0;
  }

  .app-main {
    width: calc(100vw - 250px);
  }
}
</style>
