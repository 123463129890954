<template>
    <div class="navbar-container">
        <!-- logo标签 -->
        <div class="navbar-logo">
            <LogoView />
        </div>

        <div class="navbar-menu">
            <!-- 工具组件 -->
            <el-tooltip
                effect="dark"
                :content="isScreenfull ? '退出全屏' : '全屏'"
                placement="bottom"
            >
                <div class="navbar-menu-item" @click="screenfull">
                    <div class="navbar-menu-icon">
                        <i class="el-icon-full-screen"></i>
                    </div>
                </div>
            </el-tooltip>

            <el-dropdown class="avatar-container" @command="handleCommand">
                <div class="avatar-wrapper">
                    <el-avatar v-if="avatar" :size="35" :src="avatar" />
                    <div class="avatar-text" v-else>
                        {{ nickname.split('')[0] }}
                    </div>
                    <div>{{ nickname }}</div>
                </div>
                <el-dropdown-menu slot="dropdown" class="user-dropdown">
                    <el-dropdown-item command="openUserinfo">
                        <span>
                            <i class="el-icon-user" />
                            个人信息
                        </span>
                    </el-dropdown-item>
                    <el-dropdown-item command="updatePwd">
                        <span>
                            <i class="el-icon-unlock" />
                            修改密码
                        </span>
                    </el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
            <!-- websocket状态 -->
            <div
                v-if="wsStatus == 1 || wsStatus == 2 || wsStatus == 3"
                class="ws-status"
                :class="
                    wsStatus == 1
                        ? 'ws-status-1'
                        : wsStatus == 2
                        ? 'ws-status-2'
                        : wsStatus == 3
                        ? 'ws-status-3'
                        : ''
                "
            ></div>
            <!-- 退出登录 -->
            <el-tooltip effect="dark" content="退出登录" placement="bottom">
                <div class="navbar-menu-item" @click="logout">
                    <div class="navbar-menu-icon">
                        <i class="el-icon-switch-button" style="color: red"></i>
                    </div>
                </div>
            </el-tooltip>
        </div>
        <!-- 弹窗组件 -->
        <updatePwd ref="updatePwd" />
        <userInfo ref="userInfo" />
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import screenfull from 'screenfull'
import LogoView from '@/layout/NavBar/LogoView'
import updatePwd from '@/components/updatePwd'
import userInfo from '@/components/userInfo'
export default {
    name: 'NavBar',
    components: { LogoView, updatePwd, userInfo },
    data() {
        return {
            isScreenfull: false
        }
    },
    computed: {
        ...mapGetters(['nickname', 'avatar', 'wsStatus'])
    },
    methods: {
        // 退出登录
        logout() {
            this.$baseConfirm('是否退出系统', null, () => {
                this.$store.dispatch('userinfo/logout')
            })
        },
        //下拉菜单监听
        handleCommand(command) {
            switch (command) {
                case 'openUserinfo':
                    // 用户信息
                    this.$refs['userInfo'].show()
                    break
                case 'updatePwd':
                    // 修改密码
                    this.$refs['updatePwd'].show()
                    break
            }
        },
        // 全屏
        screenfull() {
            if (!screenfull.isEnabled) {
                this.$message({
                    message: '您的浏览器不支持全屏',
                    type: 'warning'
                })
                return false
            }
            screenfull.toggle()
            this.isScreenfull = !this.isScreenfull
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';

.navbar-container {
    -moz-user-select: none;
    /* Firefox私有属性 */
    -webkit-user-select: none;
    /* WebKit内核私有属性 */
    -ms-user-select: none;
    /* IE私有属性(IE10及以后) */
    -khtml-user-select: none;
    /* KHTML内核私有属性 */
    -o-user-select: none;
    /* Opera私有属性 */
    user-select: none;
    /* CSS3属性 */
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.navbar-menu {
    display: flex;
    align-items: center;
    float: right;
    height: 100%;
    margin-right: 10px;

    .navbar-menu-item {
        height: 35px;
        width: 35px;
        margin: 0 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    .navbar-menu-item:hover {
        background-color: $hoverBackgroundColor;
        border-radius: 5px;
    }

    .navbar-menu-icon {
        font-size: 18px;
    }

    .avatar-container {
        cursor: pointer;
        margin: 0 5px;

        .avatar-wrapper {
            display: flex;
            align-items: center;

            div {
                margin-left: 5px;
                font-size: 16px;
            }

            .avatar-text {
                background-color: $base-color-default;
                height: 35px;
                width: 35px;
                line-height: 35px;
                color: #ffffff;
                text-align: center;
                border-radius: 50%;
                font-weight: bold;
            }
        }
    }

    .ws-status {
        height: 6px;
        width: 6px;
        border-radius: 50%;
    }

    .ws-status-1 {
        background-color: #09ff00;
    }

    .ws-status-2 {
        background-color: #ffa600;
    }

    .ws-status-3 {
        background-color: #ff0000;
    }
}
</style>
