<template>
  <div class="logo-container">
    <div class="logo-img">
      <img src="@/assets/logo.png" />
    </div>
    <div class="logo-title">{{ title }}</div>
  </div>
</template>

<script>
export default {
  name: 'LogoView',
  data() {
    return {
      title: process.env.VUE_APP_NAME
    }
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
.logo-container {
  display: flex;
  align-items: center;
  height: 100%;

  .logo-img {
    width: 30px;
    height: 30px;
    margin: 0 10px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .logo-title {
    font-size: 18px;
  }
}</style>
