import Vue from 'vue'
import Vuex from 'vuex'
import userinfo from './modules/userinfo'
import websocket from './modules/websocket'
import createPersistedState from 'vuex-persistedstate'
Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        // 存放数据 和data类似
    },
    mutations: {
        // 用来修改state和getters里面的数据
    },
    getters: {
        // 相当于计算属性
        token: (state) => state.userinfo.token,
        avatar: (state) => state.userinfo.avatar,
        nickname: (state) => state.userinfo.nickname,
        role: (state) => state.userinfo.role,
        routes: (state) => state.userinfo.routes,
        pres: (state) => state.userinfo.pres,
        wsStatus: (state) => state.websocket.wsStatus
    },
    actions: {
        // vuex中用于发起异步请求
    },
    modules: {
        // 拆分模块
        userinfo,
        websocket
    },
    plugins: [
        createPersistedState({
            storage: window.sessionStorage,
            reducer(val) {
                return {
                    // 只储存state中的user
                    user: val.user
                }
            }
        })
    ]
})
