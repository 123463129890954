<template>
    <el-menu
        :default-active="activeMenu"
        mode="vertical"
        :unique-opened="true"
        :collapse-transition="true"
        router
    >
        <SidebarItem
            v-for="(route, index) in routes"
            :key="index + '_' + route.path"
            :item="route"
            :base-path="route.path"
        />
    </el-menu>
</template>

<script>
import SidebarItem from '@/layout/SideBar/SidebarItem'
export default {
    name: 'SideBar',
    components: { SidebarItem },
    computed: {
        routes() {
            return this.$router.options.routes.concat(
                global.antRouter ? global.antRouter : []
            )
        },
        activeMenu() {
            const route = this.$route
            return route.path
        }
    },
    data() {
        return {}
    },
    created() {},
    methods: {}
}
</script>

<style lang="scss" scoped>
.sidebar-container {
    -moz-user-select: none; /* Firefox私有属性 */
    -webkit-user-select: none; /* WebKit内核私有属性 */
    -ms-user-select: none; /* IE私有属性(IE10及以后) */
    -khtml-user-select: none; /* KHTML内核私有属性 */
    -o-user-select: none; /* Opera私有属性 */
    user-select: none; /* CSS3属性 */
    min-height: calc(100vh - 66px);
    background-color: #ffffff;
    // border-bottom: 1px solid #bdbdbd;
    width: 100%;
    overflow: auto;
    margin-top: 5px;
}
</style>
