import axios from 'axios'
import { MessageBox, Message } from 'element-ui'
import store from '@/store'
import { getToken } from '@/utils/auth'

const service = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    timeout: 60000,
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
})

// request interceptor
service.interceptors.request.use(
    (config) => {
        const token = getToken()
        if (token) {
            config.headers.WebAuthorization = token
        }
        return config
    },
    (error) => {
        console.log(error)
        return Promise.reject(error)
    }
)

// response interceptor
service.interceptors.response.use(
    (response) => {
        const res = response.data
        switch (res.code) {
            case 403:
                if (store.getters.nickname) {
                    MessageBox.confirm(res.message, '确认退出', {
                        confirmButtonText: '重新登录',
                        cancelButtonText: '取消',
                        type: 'warning'
                    })
                        .then(() => {
                            store.dispatch('userinfo/resetToken').then(() => {
                                location.reload()
                            })
                        })
                        .catch(() => {})
                }
                return Promise.resolve(res)
            case 401: {
                Message({
                    message: res.message || 'Error',
                    type: 'error',
                    duration: 5000
                })
                return Promise.resolve(res)
            }
            case 400: {
                Message({
                    message: res.message || 'Error',
                    type: 'error',
                    duration: 5000
                })
                return Promise.resolve(res)
            }
            case 500: {
                Message({
                    message: res.message || 'Error',
                    type: 'error',
                    duration: 5000
                })
                return Promise.resolve(res)
            }
            default: {
                return Promise.resolve(res)
            }
        }
    },
    (error) => {
        console.log(error)
        Message({
            message: '网络错误,请稍后重试',
            type: 'error',
            duration: 5000
        })
        return error.message
    }
)
export default service
