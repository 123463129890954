<template>
    <div>
        <el-upload
            class="avatar-uploader"
            :action="FileUploadUrl"
            :accept="accept"
            :multiple="false"
            :headers="headers"
            :show-file-list="false"
            :on-success="handleSuccess"
            :on-error="handleError"
            :before-upload="beforeAvatarUpload"
        >
            <!-- <img v-if="imageUrl" :src="imageUrl" object-fit="cover" class="avatar" /> -->
            <el-image
                v-if="imageUrl"
                style="width: 100%; height: 100%"
                :src="imageUrl"
                fit="cover"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon" />
        </el-upload>
    </div>
</template>

<script>
import { getToken } from '@/utils/auth'

export default {
    name: 'UploadImageView',
    props: {
        value: {
            type: String,
            default: ''
        },
        accept: {
            type: String,
            default: '.png,.jpg,.jpeg'
        },
        // 上传url
        // eslint-disable-next-line vue/prop-name-casing
        FileUploadUrl: {
            type: String,
            default: process.env.VUE_APP_API_URL + '/web/file/upload'
        },
        // 上传是否携带headers信息
        headers: {
            type: Object,
            default: () => {
                return {
                    WebAuthorization: getToken()
                }
            }
        }
    },
    watch: {
        value: {
            handler: function (newVal, oldVal) {
                if (newVal) {
                    this.imageUrl = newVal
                    if (oldVal) {
                        this.$http.post('/web/file/delete', {
                            fileUrl: oldVal
                        })
                    }
                } else {
                    this.imageUrl = ''
                }
            },
            immediate: true
        }
    },
    data() {
        return {
            imageUrl: ''
        }
    },
    methods: {
        beforeAvatarUpload(file) {
            return new Promise((resolve, reject) => {
                const tmp = file.name.split('.')
                const ext = tmp[1]
                if (this.accept && this.accept.indexOf(ext) === -1) {
                    this.$message.error(`上传图片只能是 ${this.accept} 格式!`)
                    return reject(false)
                } else {
                    return resolve(true)
                }
            })
        },
        // 上传成功回调
        handleSuccess(response) {
            if (response.success) {
                this.imageUrl = response.data.url
                this.$emit('input', this.imageUrl)
            } else {
                this.$message.error(`${response.message}`)
            }
        },
        // 上传失败回调
        handleError() {
            this.$baseMessage(`上传失败`, 'error')
        }
    }
}
</script>

<style lang="scss" scoped>
::v-deep .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    width: 80px;
    height: 80px;
}

::v-deep .el-upload:hover {
    border-color: #409eff;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 80px;
    height: 80px;
    line-height: 80px;
    text-align: center;
}
</style>
