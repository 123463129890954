import axios from '@/utils/request'

/**
 * http请求工具
 *
 * 封装axios的get和post方法，方便获取data结果，减少data.data的写法
 *
 * @author cicigodd
 * @date 2021/4/2 16:09
 */
export default {
    /**
     * 封装get请求
     *
     * @author cicigodd
     * @date 2021/4/2 16:13
     */
    getTB(url, params) {
        if (params === undefined) {
            params = {}
        }
        return axios.get(url, { params })
    },

    /**
     * 封装get请求
     *
     * @author cicigodd
     * @date 2021/4/2 16:13
     */
    async get(url, params) {
        if (params === undefined) {
            params = {}
        }
        const result = await axios.get(url, { params })
        return result
    },

    /**
     * 封装get请求(直接获取请求的结果)
     *
     * @author cicigodd
     * @date 2021/4/2 16:13
     */
    async getData(url, params) {
        const result = await this.get(url, params)
        return result.data
    },

    /**
     * 封装post请求
     *
     * @author cicigodd
     * @date 2021/4/2 16:13
     */
    postTB(url, params) {
        if (params === undefined) {
            params = {}
        }
        return axios.post(url, params)
    },

    /**
     * 封装post请求
     *
     * @author cicigodd
     * @date 2021/4/2 16:13
     */
    async post(url, params) {
        if (params === undefined) {
            params = {}
        }
        const result = await axios.post(url, params)
        return result
    },

    /**
     * 封装post请求
     *
     * @author cicigodd
     * @date 2021/4/2 16:13
     */
    async postData(url, params) {
        const result = await this.post(url, params)
        return result.data
    },

    /**
     * 封装put请求
     *
     * @author cicigodd
     * @date 2021/4/2 16:13
     */
    putTB(url, params) {
        if (params === undefined) {
            params = {}
        }
        return axios.put(url, params)
    },
    /**
     * 封装put请求
     *
     * @author cicigodd
     * @date 2021/4/2 16:13
     */
    async put(url, params) {
        if (params === undefined) {
            params = {}
        }
        const result = await axios.put(url, params)
        return result
    },

    /**
     * 封装put请求
     *
     * @author cicigodd
     * @date 2021/4/2 16:13
     */
    async putData(url, params) {
        const result = await this.put(url, params)
        return result.data
    },

    /**
     * 封装delete请求
     *
     * @author cicigodd
     * @date 2021/4/2 16:13
     */
    deleteTB(url, params) {
        if (params === undefined) {
            params = {}
        }
        return axios.delete(url, params)
    },

    /**
     * 封装delete请求
     *
     * @author cicigodd
     * @date 2021/4/2 16:13
     */
    async delete(url, params) {
        if (params === undefined) {
            params = {}
        }
        const result = await axios.delete(url, { params })
        return result
    },

    /**
     * 封装delete请求
     *
     * @author cicigodd
     * @date 2021/4/2 16:13
     */
    async deleteData(url, params) {
        const result = await this.delete(url, params)
        return result.data
    },

    /**
     * 上传文件
     *
     * @author cicigodd
     * @date 2021/4/2 16:13
     */
    async upload(data) {
        const result = await axios({
            url: '/web/file/upload',
            method: 'POST',
            headers: { 'Content-Type': 'multipart/articles-data' },
            data
        })
        return result.data
    }
}
