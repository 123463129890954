import Layout from '@/layout'

export function arraySort(property) {
    return function (a, b) {
        let value1 = a[property]
        let value2 = b[property]
        return value1 - value2
    }
}

export function filterAsyncRouter(data) {
    let menus = []
    data.forEach((item) => {
        const menu = {
            path: item.path,
            name: item.name,
            children: [],
            hidden: item.hidden,
            meta: item.meta
        }
        if (item.component != null && item.component !== '') {
            menu.component =
                item.component === 'Layout'
                    ? Layout
                    : (resolve) =>
                          require([`@/views${item.component}`], resolve)
        }
        if (item.path == '/') {
            menu.path = item.path
            menu.redirect = item.path + item.children[0].path
            delete menu['name']
            delete menu['hidden']
            delete menu['meta']
        }
        if (item.children) {
            generaMenu(menu.children, item.children)
        }
        menus.push(menu)
    })
    return menus
}

export function generaMenu(routes, data) {
    data.forEach((item) => {
        const menu = {
            path: item.path,
            name: item.name,
            children: [],
            hidden: item.hidden,
            meta: item.meta
        }
        if (item.component != null && item.component !== '') {
            menu.component =
                item.component === 'Layout'
                    ? Layout
                    : (resolve) =>
                          require([`@/views${item.component}`], resolve)
        }
        if (item.component === 'Layout') {
            generaMenu(menu.children, item.children)
        }
        if (!item.children) {
            delete menu['children']
            delete menu['hidden']
        }
        routes.push(menu)
    })
    return routes
}
