import { getToken, setToken, removeToken } from '@/utils/auth'
import http from '@/utils/http'

const defaultState = {
    // 存放数据 和data类似
    token: getToken(),
    nickname: '',
    avatar: '',
    role: [],
    routes: [],
    pres: []
}

const state = defaultState

const mutations = {
    // 用来修改state和getters里面的数据
    RESET_STATE: (state) => {
        Object.assign(state, defaultState)
    },
    SET_TOKEN: (state, token) => {
        state.token = token
    },
    SET_NICKNAME: (state, nickname) => {
        state.nickname = nickname
    },
    SET_AVATAR: (state, avatar) => {
        state.avatar = avatar
    },
    SET_ROLE: (state, role) => {
        state.role = role
    },
    SET_ROUTES: (state, routes) => {
        state.routes = routes
    },
    SET_PRES: (state, pres) => {
        state.pres = pres
    }
}
const actions = {
    //登陆后执行
    login({ commit }, data) {
        commit('SET_TOKEN', data.token)
        setToken(data.token)
    },
    //退出后执行
    async logout({ commit }) {
        const data = await http.post('/web/logout')
        if (data.success) {
            removeToken()
            commit('RESET_STATE')
            location.reload()
        }
    },
    //获取用户信息
    async getUserInfo({ commit }) {
        const data = await http.get('/web/sysUser/getUserInfo')
        if (data.success) {
            commit('SET_NICKNAME', data.data.nickname)
            commit('SET_AVATAR', data.data.avatar)
        }
        return data
    },
    //获取菜单
    async getMenu({ commit }) {
        const data = await http.get('/web/sysMenu/getRoutes')
        if (data.success) {
            commit('SET_ROUTES', data.data)
        }
        return data
    },
    //重置token
    resetToken({ commit }) {
        return new Promise((resolve) => {
            removeToken()
            commit('RESET_STATE')
            resolve()
        })
    }
}
export default {
    namespaced: true,
    state,
    mutations,
    actions
}
