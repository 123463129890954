const TokenKey = 'AdminToken'

export function getToken() {
    return window.localStorage.getItem(TokenKey)
}

export function setToken(token) {
    return window.localStorage.setItem(TokenKey, token)
}

export function removeToken() {
    return window.localStorage.removeItem(TokenKey)
}

export function hasAuth(perms, perm) {
    let hasA = false
    perms.forEach((p) => {
        if (p.indexOf(perm) !== -1) {
            hasA = true
            return false
        }
    })
    return hasA
}
