import { ClientMessageEnum } from './clientMessageEnum.js'
import store from '../../store'
export default class websocketUtil {
    constructor(url) {
        this.is_open_socket = false //避免重复连接
        this.url = url //地址
        //心跳消息
        this.data = { clientMsgType: ClientMessageEnum.APP_HEART }
        //心跳检测
        this.timeout = 5000 //多少秒执行检测
        this.heartbeatInterval = null //检测服务器端是否还活着
        this.reconnectTimeOut = null //重连之后多久再次重连
        try {
            return this.connectSocketInit()
        } catch (e) {
            console.log('catch')
            this.is_open_socket = false
            this.reconnect()
        }
    }

    // 进入这个页面的时候创建websocket连接【整个页面随时使用】
    connectSocketInit() {
        if (typeof WebSocket === 'undefined') {
            alert('您的浏览器不支持socket')
        } else {
            // 实例化socket
            this.socketTask = new WebSocket(this.url)
        }
        // 监听socket连接
        this.socketTask.onopen = () => {
            console.log('WebSocket连接正常！')
            store.dispatch('websocket/updateWsStatus', 1)
            clearTimeout(this.reconnectTimeOut)
            clearTimeout(this.heartbeatInterval)
            this.is_open_socket = true
            this.start()
            // 注：只有连接正常打开中 ，才能正常收到消息
            this.socketTask.onmessage = () => {
                // console.log(res.data)
            }
        }

        // 这里仅是事件监听【如果socket关闭了会执行】
        this.socketTask.onclose = () => {
            console.log('已经被关闭了')
            store.dispatch('websocket/updateWsStatus', 3)
            this.is_open_socket = false
            this.reconnect()
        }
    }

    //发送消息
    send(value) {
        // 注：只有连接正常打开中 ，才能正常成功发送消息
        this.socketTask.send(JSON.stringify(value))
    }
    //开启心跳检测
    start() {
        this.heartbeatInterval = setInterval(() => {
            this.send(this.data)
        }, this.timeout)
    }
    //重新连接
    reconnect() {
        store.dispatch('websocket/updateWsStatus', 2)
        //停止发送心跳
        clearInterval(this.heartbeatInterval)
        //如果不是人为关闭的话，进行重连
        if (!this.is_open_socket) {
            this.reconnectTimeOut = setTimeout(() => {
                this.connectSocketInit()
            }, 3000)
        }
    }
    //外部获取消息
    getMessage(callback) {
        this.socketTask.onmessage = (res) => {
            return callback(JSON.parse(res.data))
        }
    }
}
