<template>
    <el-dialog
        :title="title"
        :visible.sync="dialogFormVisible"
        width="800px"
        :fullscreen="false"
        :close-on-click-modal="false"
        :modal-append-to-body="false"
        @close="close"
    >
        <el-form ref="form" :model="form" :rules="rules" label-width="100px">
            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item label="头像" prop="avatar">
                        <UploadImage v-model.trim="form.avatar" />
                    </el-form-item>
                    <el-form-item label="昵称" prop="nickname">
                        <el-input
                            v-model.trim="form.nickname"
                            autocomplete="off"
                        />
                    </el-form-item>
                    <el-form-item label="性别" prop="sex">
                        <el-radio-group v-model="form.sex">
                            <el-radio label="1">男</el-radio>
                            <el-radio label="2">女</el-radio>
                            <el-radio label="3">未知</el-radio>
                        </el-radio-group>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="姓名" prop="realname">
                        <el-input
                            v-model.trim="form.realname"
                            autocomplete="off"
                        />
                    </el-form-item>
                    <el-form-item label="身份证号" prop="idcard">
                        <el-input
                            v-model.trim="form.idcard"
                            autocomplete="off"
                        />
                    </el-form-item>
                    <el-form-item label="手机" prop="phone">
                        <el-input
                            v-model.trim="form.phone"
                            autocomplete="off"
                        />
                    </el-form-item>
                    <el-form-item label="邮箱" prop="email">
                        <el-input
                            v-model.trim="form.email"
                            autocomplete="off"
                        />
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button @click="close">取 消</el-button>
            <el-button type="primary" @click="save">确 定</el-button>
        </div>
    </el-dialog>
</template>

<script>
import UploadImage from '@/components/UploadFile/UploadImage'
export default {
    name: 'UserInfoView',
    components: { UploadImage },
    data() {
        return {
            options: [],
            form: {},
            rules: {
                nickname: [
                    {
                        required: true,
                        message: '请输入昵称',
                        type: 'string',
                        trigger: 'blur'
                    }
                ],
                sex: [
                    {
                        required: true,
                        message: '请上选择性别',
                        type: 'string',
                        trigger: 'blur'
                    }
                ],
                password: [
                    {
                        required: true,
                        message: '请输入密码',
                        type: 'string',
                        trigger: 'blur'
                    }
                ]
            },
            title: '',
            dialogFormVisible: false
        }
    },
    created() {},
    methods: {
        async show() {
            this.title = '编辑个人信息'
            this.dialogFormVisible = true
            this.form = await this.$http.getData('/web/sysUser/getUserInfo')
        },
        close() {
            this.$refs['form'].resetFields()
            this.form = this.$options.data().form
            this.dialogFormVisible = false
        },
        save() {
            this.$refs['form'].validate(async (valid) => {
                if (valid) {
                    const data = await this.$http.put(
                        '/web/sysUser/updateUserInfo',
                        this.form
                    )
                    if (data.success) {
                        // 请求获取用户信息
                        this.$store.dispatch('userinfo/getUserInfo')
                        this.$baseMessage(data.message, 'success')
                        this.close()
                    }
                } else {
                    return false
                }
            })
        }
    }
}
</script>
