import Websocket from '@/utils/websocket/websocketUtil'
import Vue from 'vue'
const defaultState = {
    // 存放数据 和data类似
    wsStatus: 0
}

const state = defaultState

const mutations = {
    // 用来修改state和getters里面的数据
    SET_WSSTATUS: (state, wsStatus) => {
        state.wsStatus = wsStatus
    }
}
const actions = {
    //初始化socket
    initSocket(id) {
        //开启websocket
        const websocket = new Websocket(process.env.VUE_APP_WS_URL + id)
        Vue.prototype.$socket = websocket
    },
    //修改websocket状态
    updateWsStatus({ commit }, wsStatus) {
        commit('SET_WSSTATUS', wsStatus)
    }
}
export default {
    namespaced: true,
    state,
    mutations,
    actions
}
