import router from '../router'
import store from '../store'
import { Message } from 'element-ui'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { getToken } from '@/utils/auth'
import { filterAsyncRouter } from '@/utils/menu'
import getPageTitle from '@/utils/get-page-title'

// 进度条配置
NProgress.configure({ showSpinner: false })
// 路由白名单
const whiteList = ['/login']

router.beforeEach(async (to, from, next) => {
    // 进度条开始
    NProgress.start()

    // 设置页面标题
    document.title = getPageTitle(process.env.VUE_APP_NAME, to.meta.title)

    // 获取登录标识
    const hasToken = getToken()

    if (hasToken) {
        // 已登录
        // if (to.path === '/login') {
        //     next({ path: '/' })
        // } else {
        const hasGetUserInfo = store.getters.nickname
        if (hasGetUserInfo) {
            if (!global.antRouter) {
                // 获取菜单信息
                await store.dispatch('userinfo/getMenu') // 请求获取路由信息
                if (store.getters.routes.length < 1) {
                    global.antRouter = []
                    next()
                }
                // 1.过滤路由
                const menus = filterAsyncRouter(store.getters.routes)
                // 2.动态添加路由
                menus.forEach((item) => {
                    router.addRoute(item)
                })
                // 3.将路由数据传递给全局变量，做侧边栏菜单渲染工作
                global.antRouter = menus
                next({
                    ...to,
                    replace: true
                })
            }
            next()
        } else {
            try {
                // 请求获取用户信息
                const data = await store.dispatch('userinfo/getUserInfo')
                if (data.code == 403) {
                    Message.error({ message: data.message })
                    store.dispatch('userinfo/resetToken')
                    next(`/login`)
                } else if (data.success) {
                    // 获取菜单信息
                    await store.dispatch('userinfo/getMenu') // 请求获取路由信息
                    if (store.getters.routes.length < 1) {
                        global.antRouter = []
                        next()
                    }
                    // 1.过滤路由
                    const menus = filterAsyncRouter(store.getters.routes)
                    // 2.动态添加路由
                    menus.forEach((item) => {
                        router.addRoute(item)
                    })
                    // 3.将路由数据传递给全局变量，做侧边栏菜单渲染工作
                    global.antRouter = menus
                    next({
                        ...to,
                        replace: true
                    })
                }
            } catch (error) {
                // 清空token跳转首页
                store.dispatch('userinfo/resetToken')
                Message.error({ message: error || '发生错误' })
                next(`/login`)
                NProgress.done()
            }
        }
        // }
    } else {
        // 未登录 判断是否白名单页面
        if (whiteList.indexOf(to.path) !== -1) {
            // 是白名单跳转页面
            next()
        } else {
            // 不是白名单跳转登录页面
            next(`/login`)
        }
    }
})

router.afterEach(() => {
    // 进度条结束
    NProgress.done()
})
