import Vue from 'vue'
import VueRouter from 'vue-router'
// vue-router 3.0以上版本异常捕获
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch((err) => err)
}

Vue.use(VueRouter)

import Layout from '@/layout'

const routes = [
    {
        path: '/login',
        name: 'login',
        meta: {
            title: '登录',
            icon: '',
            hidden: true
        },
        component: () => import('@/views/login/index')
    },
    {
        path: '/',
        name: '',
        redirect: '/dashboard',
        component: Layout,
        meta: {
            title: '首页',
            icon: 'el-icon-s-home'
        },
        children: [
            {
                path: '/dashboard',
                name: 'dashboard',
                meta: {
                    title: '首页',
                    icon: 'el-icon-s-home',
                    pathType: '1'
                },
                component: () => import('@/views/dashboard')
            }
        ]
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
