<template>
    <div v-if="!item.meta.hidden">
        <template
            v-if="
                hasOneShowingChild(item.children, item) &&
                !onlyOneChild.children
            "
        >
            <div v-if="onlyOneChild.meta.pathType == '1'">
                <el-menu-item :index="onlyOneChild.path" :route="onlyOneChild">
                    <template slot="title">
                        <i :class="onlyOneChild.meta.icon"></i>
                        <span>{{ onlyOneChild.meta.title }}</span>
                    </template>
                </el-menu-item>
            </div>
            <el-link
                v-if="onlyOneChild.meta.pathType == '2'"
                :href="onlyOneChild.meta.url"
                :underline="false"
                target="_blank"
                style="display: block; width: 100%"
            >
                <div class="el-menu-item">
                    <i :class="onlyOneChild.meta.icon"></i>
                    <span>{{ onlyOneChild.meta.title }}</span>
                </div>
            </el-link>
            <el-link
                v-if="onlyOneChild.meta.pathType == '3'"
                :href="getPath(onlyOneChild)"
                :underline="false"
                target="_blank"
                style="display: block; width: 100%"
            >
                <div class="el-menu-item">
                    <i :class="onlyOneChild.meta.icon"></i>
                    <span>{{ onlyOneChild.meta.title }}</span>
                </div>
            </el-link>
        </template>
        <div v-else>
            <el-link
                v-if="onlyOneChild.meta.pathType == '2'"
                :href="onlyOneChild.meta.url"
                :underline="false"
                target="_blank"
                style="display: block; width: 100%"
            >
                <div class="el-menu-item">
                    <i :class="onlyOneChild.meta.icon"></i>
                    <span>{{ onlyOneChild.meta.title }}</span>
                </div>
            </el-link>
            <el-link
                v-else-if="onlyOneChild.meta.pathType == '3'"
                :href="getPath(onlyOneChild)"
                :underline="false"
                target="_blank"
                style="display: block; width: 100%"
            >
                <div class="el-menu-item">
                    <i :class="onlyOneChild.meta.icon"></i>
                    <span>{{ onlyOneChild.meta.title }}</span>
                </div>
            </el-link>
            <el-submenu v-else :index="item.path">
                <template slot="title">
                    <i :class="item.meta.icon"></i>
                    <span>{{ item.meta.title }}</span>
                </template>
                <sidebar-item
                    v-for="(child, index) in item.children"
                    :key="index + '_' + child.path"
                    :item="child"
                    :base-path="child.path"
                />
            </el-submenu>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SidebarItem',
    components: {},
    props: {
        // route object
        item: {
            type: Object,
            required: true
        },
        isNest: {
            type: Boolean,
            default: false
        },
        basePath: {
            type: String,
            default: ''
        }
    },
    data() {
        this.onlyOneChild = null
        return {}
    },
    methods: {
        getPath(item) {
            console.log(window.location.host + item.path)
            return `${window.location.protocol}//${window.location.host}${item.path}`
        },
        hasOneShowingChild(children = [], parent) {
            const showingChildren = children.filter((item) => {
                if (item.hidden) {
                    return false
                } else {
                    this.onlyOneChild = item
                    return true
                }
            })
            if (parent.meta.menuType == 1) {
                return false
            }
            if (showingChildren.length === 1) {
                return true
            }
            if (showingChildren.length === 0) {
                this.onlyOneChild = { ...parent, noShowingChildren: true }
                return true
            }
            return false
        }
    }
}
</script>
